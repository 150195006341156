import { NEVER } from 'rxjs';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import fromApp, { SetCulture } from '../../core/states/app';

@Injectable({
  providedIn: 'root',
})
export class CultureResolverService implements Resolve<any> {
  constructor(
    private storage: Storage,
    private appStore: Store<fromApp.AppState>,
  ) {}

  resolve(): Promise<any>  {
    return this.storage.get('culture').then(culture => {
      if (culture) {
        this.appStore.dispatch(new SetCulture(culture));

        return culture;
      }

      return NEVER;
    });
  }
}
