import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient) {}

  public login(email, password) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    const url = environment.login_api_endpoint;

    const data = { email, password };

    return this.http.post(url, data, options)
      .pipe(
        switchMap((response: any)=> {
          debugger;
          const name = `${response.data.primeiroNome} ${response.data.sobrenome}`;
          const email = data.email;
          const firstName = response.data.primeiroNome;
          const lastName = response.data.sobrenome;
          const userToken = response.data.token;

          const user = {
            token: userToken,
            profile: {
              email,
              name,
              firstName,
              lastName
            }
          };

          return of(user);
        })
      );
  }

  public register(data: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    const url = environment.accounts_api_endpoint;

    return this.http.post(url, data, options)
              .pipe(
                switchMap(() => of(true)),
              );
  }

  public forgotPassword(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    const url = environment.forgot_password_api_endpoint;

    return this.http.post(url, data, options)
      .pipe(
        switchMap(() => of(true)),
      );
  }
}
