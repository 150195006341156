import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { switchMap, tap, take } from 'rxjs/operators';

import fromQuote, { FetchQuoteResponse, getQuote } from '../../core/states/quote';

@Injectable({
  providedIn: 'root',
})
export class QuoteResolverService implements Resolve<any> {
  constructor(
    private storage: Storage,
    private quoteStore: Store<fromQuote.QuoteState>,
  ) {}

  resolve(): Observable<any> {
    return this.quoteStore.select(getQuote).pipe(
      take(1),
      switchMap(quote => {
        if (!quote) {
          return this.storage.get('quote');
        }

        return of(quote);
      }),
      tap(quote => {
        if (quote) {
          this.quoteStore.dispatch(new FetchQuoteResponse({ data: quote }));
        }
      }),
    );
  }
}
