import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { take, tap, switchMap } from 'rxjs/operators';

import fromUser, { getUser, SetUser } from '../../core/states/user';

@Injectable({
  providedIn: 'root',
})
export class UserResolverService implements Resolve<any> {
  constructor(
    private storage: Storage,
    private userStore: Store<fromUser.UserState>,
  ) {}

  resolve(): Observable<any>  {
    return this.userStore.select(getUser).pipe(
      take(1),
      switchMap((user: any) => {
        if (!user.data) {
          return this.storage.get('user');
        }

        return of(user.data);
      }),
      tap(user => {
        if (user) {
          this.userStore.dispatch(new SetUser(user));
        }
      })
    );
  }
}
