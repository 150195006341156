import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { take, tap, switchMap } from 'rxjs/operators';

import fromLocations, { getLocations, SetLocations } from '../../core/states/locations';

@Injectable({
  providedIn: 'root',
})
export class LocationsResolverService implements Resolve<any> {
  constructor(
    private storage: Storage,
    private locationsStore: Store<fromLocations.LocationsState>,
  ) {}

  resolve(): Observable<any>  {
    return this.locationsStore.select(getLocations).pipe(
      take(1),
      switchMap((locations: any) => {
        if (!locations.pickupLocation) {
          return this.storage.get('locations');
        }

        return of(locations);
      }),
      tap(locations => {
        if (locations) {
          this.locationsStore.dispatch(new SetLocations(locations));
        }
      })
    );
  }
}
