import { NEVER } from 'rxjs';
import { Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import fromApp, { SetCurrency } from '../../core/states/app';

@Injectable({
  providedIn: 'root',
})
export class CurrencyResolverService implements Resolve<any> {
  constructor(
    private storage: Storage,
    private appStore: Store<fromApp.AppState>,
  ) {}

  resolve(): Promise<any> {
    return this.storage.get('currency').then(currency => {
      if (currency) {
        this.appStore.dispatch(new SetCurrency(currency));

        return currency;
      }

      return NEVER;
    });
  }
}
